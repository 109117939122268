<script>
  // import gql from "graphql-tag";
  import { Pie, mixins } from "vue-chartjs";

  const { reactiveProp } = mixins;
  export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: {
      analyticsData: Object,
      chartData: Object,
    },
    name: "PieChart",

    data() {
      return {
        getProfitAndExpenses: [],
        chart_data: {
          labels: ["Income", "Expenses", "Profit"],
          datasets: [
            {
              label: "Dataset 1",
              data: [],
              backgroundColor: ["blue", "red", "green"],
            },
          ],
        },
        chart_options: {
          legend: {
            position: "bottom",
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },

    methods: {
      SetChartData() {
        this.chart_data.datasets[0].data = [
          this.analyticsData.totalIncome,
          this.analyticsData.totalExpenses,
          this.analyticsData.totalProfit,
        ];
        this.renderChart(this.chart_data, this.chart_options);
      },
    },
    watch: {
      analyticsData: {
        deep: true,
        handler() {
          this.SetChartData();
        },
      },
    },
    created() {},
    mounted() {
      this.SetChartData();
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #bar-chart {
    margin-left: auto;
    margin-right: auto;
  }
</style>
