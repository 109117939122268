<template>
  <div>
    <div class=" mt-6 bg-white p-6 rounded-lg ">
      <div class="mb-5">
        <h5 class="text-darkblue mb-2 text-center">Analytics</h5>
        <div class=" mt-2 mb-2  ml-auto mr-auto md:max-w-1/2">
          <t-datepicker
            :classes="datePickerDefaultClass"
            :dateFormat="'Y-m-d H:i:S'"
            :userFormat="'Y-m-d'"
            :placeholder="'Select date range'"
            :range="true"
            v-model="selectedDate"
          />
        </div>
        <button
          @click="SetDateFilter"
          class="pl-3 ml-auto mr-auto md:text-xs block hover:text-black  pr-3 border-2 text-white bg-darkblue hover:bg-green-400 hover:border-green-400 pt-1 pb-1 rounded-lg"
          type="button"
        >
          Get Analytics
        </button>
      </div>
      <AnalyticsChart
        v-if="!hideChart"
        :analyticsData="{ totalProfit, totalExpenses, totalIncome }"
      />
      <p
        v-if="hideChart && !loading.pieChart"
        class="text-xs mt-2 mb-2 text-darkblue text-center"
      >
        There is no data to display for this period,consider changing the date
        range.
      </p>

      <div
        v-if="loading.pieChart === true"
        class="flex justify-center flex-col"
      >
        <div>
          <p class="text-red-700 md:text-xs  text-center font-bold  ">
            Loading,please wait...
          </p>
        </div>
        <div class="mt-2">
          <svg
            class="ml-auto mr-auto animate-spin"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="tableData.length > 0" class="p-6 mt-1">
      <div class="overflow-auto md:overflow-visible mt-4 md:mt-0">
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
        </t-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import gql from "graphql-tag";
  import { datePickerMixin } from "@/assets/helpers/datePickerMixin";
  import { tableMixin } from "@/assets/helpers/tableMixin";

  import AnalyticsChart from "../components/AnalyticsChart.vue";
  export default {
    name: "Analytics",
    props: {},
    mixins: [datePickerMixin, tableMixin],
    components: {
      AnalyticsChart,
    },
    apollo: {
      getSystemAnalysis: {
        query: gql`
          query getSystemAnalysis($date: DateFilterInput!) {
            getSystemAnalysis(date: $date) {
              invoiceTotal
              expenseTotal
              debitStatementTotal
              creditStatementTotal
            }
          }
        `,
        variables() {
          return {
            date: this.dateFilter,
          };
        },
        skip() {
          return !this.dateFilter;
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.pieChart = true;
            return;
          }

          this.loading.pieChart = false;
        },
      },
    },
    data() {
      return {
        tableHeader: [
          {
            id: "",
            value: "field",
            text: "Total.",
            className: "",
          },
          {
            value: "amount",
            text: "Amount(₦)",
            className: "fortunate-table",
          },
        ],
        tableData: [],
        getSystemAnalysis: {
          invoiceTotal: 0,
          expenseTotal: 0,
          debitStatementTotal: 0,
          creditStatementTotal: 0,
        },
        selectedDate: [],
        dateFilter: null,

        loading: {
          pieChart: false,
        },
      };
    },
    watch: {
      dateFilter: function(newValue) {
        if (!newValue) {
          this.selctedDate = [];
        }
      },
      selectedDate: function(newValue) {
        if (newValue.length < 1) {
          this.getSystemAnalysis = {
            invoiceTotal: 0,
            expenseTotal: 0,
            debitStatementTotal: 0,
            creditStatementTotal: 0,
          };
        }
      },
      getSystemAnalysis: {
        deep: true,
        handler() {
          const totalIncome = this.totalIncome.toLocaleString();
          const totalExpenses = this.totalExpenses.toLocaleString();
          const totalProfit = this.totalProfit.toLocaleString();
          const debitStatementTotal = this.getSystemAnalysis.debitStatementTotal.toLocaleString();
          const creditStatementTotal = this.getSystemAnalysis.creditStatementTotal.toLocaleString();

          this.tableData = [
            { field: " Income", amount: totalIncome },
            { field: " Expenses", amount: totalExpenses },
            { field: " Profit", amount: totalProfit },
            {
              field: " Debit Statement",
              amount: debitStatementTotal,
            },
            {
              field: " Credit Statement",
              amount: creditStatementTotal,
            },
          ];
        },
      },
    },
    computed: {
      hideChart: function() {
        if (
          this.totalIncome < 1 &&
          this.totalExpenses < 1 &&
          this.totalProfit < 1
        ) {
          return true;
        }
        return false;
      },
      totalIncome: function() {
        return Number(this.getSystemAnalysis.invoiceTotal);
      },
      totalExpenses: function() {
        return Number(this.getSystemAnalysis.expenseTotal);
      },
      totalProfit: function() {
        return Number(this.totalIncome) - Number(this.totalExpenses);
      },

      ...mapState({
        role: "role",
      }),
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SetBarChartLoadingStatus(status) {
        this.loading.pieChart = status;
      },
      SetDateFilter() {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            return (this.dateFilter = {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            });
          }
          return (this.dateFilter = {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          });
        }
        this.dateFilter = null;
      },
      SetTodaysDate() {
        let date = Date.now();
        date = new Date(date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const FinalDate = `${year}-0${month}-${day} 00:00:00`;
        this.selectedDate[0] = FinalDate;
        this.selectedDate[1] = FinalDate;
        this.dateFilter = {
          from: new Date(this.selectedDate[0]).getTime(),
          to: new Date(this.selectedDate[1]).getTime(),
        };
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Analytics", pathName: "analytics" },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-select.open .custom-select-options {
    display: block;
  }
  .custom-select li.selected {
    background-color: rgb(52, 211, 153);
  }
</style>
